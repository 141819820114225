$(function () {
    $(document).on('click', '.js_remove_card_item', function () {
        $(this).parents('.js_cards_item').remove();
    })
    var $count = $('.js_cards_item').length;
    $('.js_add_card_item').on('click', function () {
        $count++;
        var $row = $('<div class="js_cards_item" style="border: 1px solid;">\n' +
            '                                        <div class="form-group">\n' +
            '            <label for="cards[' + $count + '][title]" class="form-control-label ">Заголовок</label>\n' +
            '        <input class="form-control" placeholder="Заголовок" name="cards[' + $count + '][title]" type="text" id="cards[' + $count + '][title]">\n' +
            '\n' +
            '                        <small class="form-text text-muted"></small>\n' +
            '            </div>\n' +
            '\n' +
            '                                        <div class="col-lg-12">\n' +
            '                                            <h6 class="heading-small text-muted mb-4">Лист</h6>\n' +
            '                                            <div class="pl-lg-4">\n' +
            '                                                <div class="form-group">\n' +
            '    \n' +
            '    <select class="form-control" multiple="multiple" data-toggle="tags" name="cards[' + $count + '][list][]" style="display: none;"></select>\n' +
            '\n' +
            '                        <strong class="help-block"></strong>\n' +
            '            </div>\n' +
            '\n' +
            '                                            </div>\n' +
            '                                        </div>\n' +
            '                                        <button type="button" class="btn btn-danger js_remove_card_item">Удалить</button>\n' +
            '                                    </div>')
        $('.js_cards_list').append($row);
        $row.find('[data-toggle="tags"]').each(function () { $(this).tagsinput({tagClass: 'badge badge-primary'}); });
    });
})